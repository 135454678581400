import Vue from 'vue';
import * as Sentry from '@sentry/vue';

const startVendorService = () => {
  let sentry = { captureException: () => {} };

  if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
    });
    sentry = {
      captureException: error => {
        Sentry.captureException(error);
      },
    };
  } else {
    console.warn('No Sentry DSN key');
  }

  return {
    notify: error => {
      sentry.captureException(error);
    },
  };
};

const vendorInstance = startVendorService();

export const errorMonitoring = {
  report(error) {
    vendorInstance.notify(error);
  },
};

const VuePlugin = {
  install(VueConstructor) {
    VueConstructor.prototype.$error = errorMonitoring;
  },
};

export default VuePlugin;

const setupToast = (app, store) => {
  const clear = () => store.dispatch('toast/clearToast');

  const show = (message = '', options = {}) => {
    clear();

    app.nextTick().then(() => {
      store.dispatch('toast/showToast', { message, options });
    });
  };

  return {
    clear,
    show,
  };
};

export default {
  install: (Vue, { store }) => {
    const toast = setupToast(Vue, store);
    Vue.prototype.$toast = toast;
  },
};

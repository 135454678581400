<template>
  <bg-toast
    class="global-toast"
    v-model="isShowToast"
    v-bind="toastOptions.props"
  >
    <bg-icon
      v-if="toastOptions.icon"
      :name="toastOptions.icon"
      :class="['global-toast__icon', `global-toast__icon-${toastOptions.icon}`]"
    />

    {{ message }}
  </bg-toast>
</template>

<script>
import { mapState } from 'vuex';
import { BgToast, BgIcon } from 'bangul-vue';

export default {
  name: 'GlobalToast',
  components: {
    BgToast,
    BgIcon,
  },
  data() {
    return {
      keyCount: 0,
      key: '',
    };
  },
  computed: {
    ...mapState('toast', ['isShow', 'message', 'options']),

    isShowToast: {
      get() {
        return this.isShow;
      },
      set(isShow) {
        this.clearOnClosed(isShow);
      },
    },

    toastOptions() {
      const { icon, ...props } = this.options;

      return {
        props,
        icon,
      };
    },
  },
  watch: {
    isShow: 'clearOnClosed',
  },
  methods: {
    clearOnClosed(isShow) {
      if (!isShow) {
        this.$toast.clear();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.global-toast {
  &__icon {
    margin-right: 5px;

    &-loading {
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
    }
  }
}
</style>

import axios from 'axios';
import getApiUrl from 'Utils/getApiUrl';

export const api = axios.create({
  baseURL: `${getApiUrl()}/pms_api/v3/singgahsini/owner-dashboard`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const apiNoPrefix = axios.create({
  baseURL: getApiUrl(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const apiBlobFile = axios.create({
  responseType: 'blob'
});

export default api;

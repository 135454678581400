const defaultToastOptions = {
  duration: 8,
};

export default {
  namespaced: true,

  state: {
    isShow: false,
    message: '',
    options: {
      ...defaultToastOptions,
    },
  },

  mutations: {
    setToastShow(state, isShow) {
      state.isShow = !!isShow;
    },
    setToastMessage(state, message) {
      state.message = message;
    },
    setToastOptions(state, options) {
      state.options = {
        ...defaultToastOptions,
        ...options,
      };
    },
  },

  actions: {
    showToast({ commit }, { message = '', options = {} }) {
      commit('setToastOptions', options);
      commit('setToastMessage', message);
      commit('setToastShow', true);
    },
    clearToast({ commit }) {
      commit('setToastShow', false);
      commit('setToastOptions', {});
      commit('setToastMessage', '');
    },
  },
};

import Cookies from 'js-cookie';
import { apiNoPrefix } from './index.js';

const options = {
  sameSite: 'strict',
  domain: process.env.VUE_APP_OAUTH2_DOMAIN_OWNER,
  // 7 days
  'max-age': '604800',
};

export const kukki = {
  setToken: token => {
    Cookies.set('access_owner_token', token.access_token, options);
    Cookies.set('refresh_owner_token', token.refresh_token, options);
  },

  getToken: what => {
    if (what === 'access') {
      return Cookies.get('access_owner_token');
    }

    if (what === 'refresh') {
      return Cookies.get('refresh_owner_token');
    }
  },

  clearToken: () => {
    Cookies.remove('access_owner_token', options);
    Cookies.remove('refresh_owner_token', options);
  },

  refreshToken: () =>
    apiNoPrefix
      .post(`/oauth/token`, {
        client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
        client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
        refresh_token: kukki.getToken('refresh'),
        grant_type: 'refresh_token',
      })
      .then(response => {
        const { access_token, refresh_token } = response.data;
        if (response.status === 200) {
          kukki.setToken({ access_token, refresh_token });
        }
      })
      .catch(error => {
        kukki.clearToken();
      }),

  setOtpToken: token => {
    Cookies.set('access_otp_token', token.access_token, options);
  },

  getOtpToken: () => Cookies.get('access_otp_token'),

  clearOtpToken: () => {
    Cookies.remove('access_otp_token', options);
  },
};

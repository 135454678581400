import CryptoES from 'crypto-es';

const cipherKeyInBase64 = 'aGhiSFpwd3hMQWpibzBQTUFOMFdvZm5mcTNJcklESWs=';

const forceFileDownload = (arrayBuffer, filename) => {
  const url = window.URL.createObjectURL(new Blob([arrayBuffer]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}`);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(link.href);
  document.body.removeChild(link);
};

const decrypt = (encryptedString, initialVector, key) => {
  const plaintextArray = CryptoES.AES.decrypt(
    encryptedString,
    CryptoES.enc.Utf8.parse(atob(key)),
    {
      iv: CryptoES.enc.Hex.parse(initialVector),
    }
  );

  const decryptedResult = CryptoES.enc.Utf8.stringify(plaintextArray);

  return decryptedResult;
};

const encrypt = (string, initialVector, key) => {
  const plaintextArray = CryptoES.AES.encrypt(
    string,
    CryptoES.enc.Utf8.parse(key),
    {
      iv: CryptoES.enc.Utf8.parse(initialVector),
      padding: CryptoES.pad.Pkcs7,
    }
  );

  return plaintextArray.toString();
};

const encryptTracker = str => {
  const secretKey = 'TNIfWZS3424gmRyg'; // Random 16 byte static string
  const randomKey = CryptoES.lib.WordArray.random(8); // Generate random 16 byte string
  const initialVector = randomKey.toString(CryptoES.enc.Hex);

  const encrypted = CryptoES.AES.encrypt(
    str,
    CryptoES.enc.Utf8.parse(secretKey),
    {
      iv: CryptoES.enc.Utf8.parse(initialVector),
      mode: CryptoES.mode.CBC,
      padding: CryptoES.pad.Pkcs7,
    }
  );

  return `${initialVector}:${encrypted.toString()}`;
}


const isMobileDevice = () => {
  const mobileWidth = window.matchMedia('(max-width: 991px)').matches;
  const hasTouchScreen = navigator.maxTouchPoints > 0;

  return mobileWidth && hasTouchScreen;
};

const isDesktopResolution = () => window.matchMedia('(min-width: 992px)').matches;

const wait = time => new Promise(resolve => setTimeout(resolve, time));

const sendNativeAppsSignal = (signalName, payload = null) => {
  const isOnIosWebview = window.webkit?.messageHandlers !== undefined;
  const isOnAndroidWebview = window.Android !== undefined;

  if (isOnIosWebview) {
    window.webkit.messageHandlers[signalName].postMessage(
      payload
    );
  } else if (isOnAndroidWebview) {
    window.Android[signalName](payload);
  }
}

const downloadFile = (type, payload) => {
  if (type === 'desktop') {
    const { fileBlob, fileName } = payload;

    const url = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  } else {
    sendNativeAppsSignal('downloadReportHandler', payload);
  }
};

export {
  cipherKeyInBase64,
  forceFileDownload,
  decrypt,
  encrypt,
  encryptTracker,
  isMobileDevice,
  isDesktopResolution,
  wait,
  sendNativeAppsSignal,
  downloadFile
};
